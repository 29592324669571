<script setup lang="ts">
import { AuthLoginForm } from '@/modules/auth/components';
import { useAuthStore } from '@/modules/auth/store/auth';

const { getUser, hasLoggedInUser } = useAuthStore();

useHead({
  title: 'Login',
});

definePageMeta({
  auth: {
    unauthenticatedOnly: true,
    navigateAuthenticatedTo: () => (hasLoggedInUser && getUser?.email_verified ? '/home' : '/login'),
  },
  layout: 'guest',
});
</script>
<template>
  <v-container class="d-flex align-center hero">
    <AuthLoginForm data-testid="login-form" />
  </v-container>
</template>
<style lang="scss" scoped>
.v-form {
  width: 100%;
  max-width: 500px;
  display: inline-block;
  position: relative;
  margin: auto;
}
</style>
