<script lang="ts" setup>
import { AppButton, AppLogo, AppPassword, AppTextField, AppTypography } from '@/core/components';
import { loginCheck } from '@/core/services/LoginCheck';
import type { CommonErrorResponse } from '@/types/ErrorResponse';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useAuthStore } from '../store/auth';
import type { LoginForm } from '../types';
import { useFilterUser } from '@/store/filterUser';

// Module: Social Login
// import { GoogleLogin, FacebookLogin } from '~/modules/social-login/components';

const { resetFilterOptions } = useFilterUser();
const { resetOptions } = useAuthStore();
onMounted(async () => {
  await resetFilterOptions();
  await resetOptions();
});

const { VALIDATIONS, PASSWORD_VALIDATION } = useValidation();
const { form, handleSubmit, getErrors } = useForm<LoginForm>({
  form: {
    email: '',
    password: '',
  },
  rules: {
    email: {
      required: VALIDATIONS.required('Email'),
      email: VALIDATIONS.email(),
    },
    password: PASSWORD_VALIDATION,
  },
});

const { user, isVerified } = storeToRefs(useAuthStore());
const router = useRouter();

const {
  pending: verifyLoading,
  execute: execSendVerify,
  error: verifyError,
} = useSubmit<{}, { data: CommonErrorResponse }>();

const { setToken } = useAuthState();
const { getSession } = useAuth();

const { data, error: serverError, execute: execSubmit, pending: loginLoading } = useSubmit();
async function handleSignIn(): Promise<void> {
  const payload = {
    username: form.value.email,
    password: form.value.password,
  };
  const { token } = await useGenerateReCaptcha('login');

  handleSubmit(async function () {
    loginLoading.value = true;
    await execSubmit('auth/login', {
      method: 'POST',
      body: payload,
      headers: {
        ...(token.value && { 'X-Recaptcha-Token': token.value }),
      },
    });

    if (data.value) {
      const newToken = data.value.data.access_token;
      setToken(newToken);
      await getSession();

      user.value = data.value.data.user;
      await nextTick();

      if (!isVerified.value) {
        await execSendVerify('auth/verification/resend', {
          method: 'POST',
          body: { via: 'email' },
          headers: {
            Authorization: `Bearer ${newToken}`,
          },
        });

        if (!verifyError.value) {
          await navigateTo('/verify');
        }
        return;
      }

      const pass = await loginCheck();
      if (pass) {
        await navigateTo('/home', { replace: true });
      }
    }
  });
}

const errors = computed(() => {
  return getErrors({ email: serverError.value?.data?.message });
});
</script>

<template>
  <v-card max-width="500" width="100%" variant="plain" elevation="0" class="mx-auto pa-6">
    <v-form @submit.prevent="handleSignIn">
      <AppLogo class="mx-auto my-6" height="100" width="100" />
      <AppTypography type="large" element="h1" class="text-center">SupportApp</AppTypography>
      <AppTypography type="body-2" class="text-center py-6">
        Enter your email address and password to login if you have an existing account.
      </AppTypography>

      <AppTextField
        v-model="form.email"
        :error-messages="errors.email"
        label="Email"
        :readonly="loginLoading"
        autocomplete="email"
        data-testid="email"
        autofocus
        class="mb-1"
        data-cy="email"
      >
      </AppTextField>

      <AppPassword v-model="form.password" :error-messages="errors.password" class="mb-1" data-cy="password" />

      <nuxt-link to="/reset-password" class="text-decoration-none">
        <AppTypography element="span" type="subheadline-1" class="text-primary">Forgot Password</AppTypography>
      </nuxt-link>

      <AppButton
        block
        class="mt-4 mb-8"
        data-cy="submit"
        data-testid="submit"
        :loading="loginLoading || verifyLoading"
        :disabled="!form.email && !form.password"
      >
        Continue
      </AppButton>

      <!-- Module: Social Login -->
      <!-- <div class="d-flex justify-center mb-6">
      <GoogleLogin />
      <FacebookLogin />
    </div> -->

      <AppTypography element="span" type="headline-2" class="d-block text-center text-decoration-none">
        Don't have an account yet?
        <nuxt-link to="/register" class="font-weight-bold text-decoration-none text-primary" data-cy="sign-up"
          >Sign up</nuxt-link
        >
      </AppTypography>
    </v-form>
  </v-card>
</template>

<style lang="scss" scoped>
.v-card {
  gap: 24px;
  border-radius: 16px;
  background-color: white;

  @media (min-width: 768px) {
    padding: 32px, 16px, 32px, 16px;
  }
}

.v-card-title {
  line-height: unset !important;
  white-space: normal;
}

.v-card-subtitle {
  white-space: normal;
}
</style>
